import { useScriptCloudflareWebAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const cloudflareWebAnalytics = useScriptCloudflareWebAnalytics({"token":"0eac742b70c040658b13c152609acddf"})
    return { provide: { $scripts: { cloudflareWebAnalytics } } }
  }
})