import { default as _91_46_46_46slug_93ECHf3oF0pLMeta } from "/opt/buildhome/repo/app/pages/[...slug].vue?macro=true";
import { default as index9xeP2sz8ZcMeta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/app/pages/index.vue").then(m => m.default || m)
  }
]