import revive_payload_client_vuuY1AMc9s from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_S7kMupOIPh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZO1RL3TJad from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t5DdHli0Qo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1.55_wjt73ey66ym6bdnkqntjvcvw3e/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_9kixICgIm6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qUg1UuDzs4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hOv2BAtFoD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1ediHnM1ef from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_eGqoNJaWUs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.2_g3in4vax6i6zdpaodqnfrcah74/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_lScmoe99r1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.4.8_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1.55.0_ters_dkukzfl2m2xd2caskjuq5q5csm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_mr6BynbedU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.0.3_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import titles_hnk2RBdVQK from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_uxkyxztneimb4lwqb6o2ztgkfy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_YDUxUsKG6D from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1_7afns7zujprworgofq22d7exse/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_doYVm1HvLP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_sass@1_7afns7zujprworgofq22d7exse/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import defaults_6o2YenIb1o from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@20.16.1_uxkyxztneimb4lwqb6o2ztgkfy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_vuuY1AMc9s,
  unhead_S7kMupOIPh,
  router_ZO1RL3TJad,
  _0_siteConfig_t5DdHli0Qo,
  payload_client_9kixICgIm6,
  navigation_repaint_client_qUg1UuDzs4,
  check_outdated_build_client_hOv2BAtFoD,
  chunk_reload_client_1ediHnM1ef,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eGqoNJaWUs,
  plugin_lScmoe99r1,
  preview_client_mr6BynbedU,
  titles_hnk2RBdVQK,
  siteConfig_YDUxUsKG6D,
  inferSeoMetaPlugin_doYVm1HvLP,
  defaults_6o2YenIb1o
]